import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { ColDef, ICellRendererParams, ValueFormatterParams, ValueFormatterFunc, ExcelStyle } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { TableViewsContext, UniverseContext, SeasonContext } from 'context';
import { isEmpty, isString, isNumber } from 'lodash';
import { useTranslation } from 'react-i18next';
import { getFormattedCurrentDateTime, getCurrentWeek } from 'utils/moment';

import { ClearFiltersButton, ConciseGridContainer } from 'components/AgGridComponents';
import { numberWithSpaceFormatter } from 'components/AgGridComponents/valueFormatters';
import Views from 'components/AgGridComponents/Views/Views';
import ImportExportXlsxComponent from 'components/ImportExportXlsxComponent';
import { Spinner } from 'components/UI';
import { ActionButtonsWrapper } from 'components/wrappers/ActionButtonsWrapper';
import { VerticalPageContent } from 'components/wrappers/VerticalPageContent';
import { COLLECTION_FIELD_PREFIX, COLLECTION_ID_COL_DEF } from 'constants/collections';
import {
  CENTERED_COL_DEF,
  COLUMN_TYPES,
  GRID_DEFAULT_COL_DEFS,
  HIGHLIGHTED_COLUMN_COL_DEFS,
  SIDE_BAR,
} from 'constants/gridConstants';
import { prepareRowData } from 'helpers/collections';
import { generateCustomFieldsColDefs } from 'helpers/customFields';
import { hasFiltersChanged } from 'helpers/grid';
import { applyColWidths, getVisibleColDefs, sortEmpty } from 'helpers/gridCols';
import { getContextMenu } from 'helpers/gridContextMenu';
import { useGridCols } from 'hooks/useGridCols';
import {
  StorePerformanceDto,
  StorePerformanceStoreSummaryDto,
  useGetCollectionsNamesQuery,
  useStoreCustomFieldsQuery,
  useStorePerformanceStoreSummaryLazyQuery,
  useGetCollectionStoreGroupsLazyQuery,
  useGetCapacityTypeQuery,
  CapacityType,
} from 'services/graphql/main';
import { useError } from 'services/utils';
import { CollectionColDef } from 'types/collections';

import { calculateData } from './common/helpers';
import { AdaptiveGridResizer, ExportButtonWrapper } from './styles';

const StorePerformanceAnalyticsTable = () => {
  const { t } = useTranslation('translation');
  const { addError } = useError();

  const { getCurrentViewCols, getView, getColumnsWidths } = useContext(TableViewsContext);
  const { selectedUniverse } = useContext(UniverseContext);
  const { selectedSeason } = useContext(SeasonContext);
  const seasonId = selectedSeason?.id;
  const universeId = selectedUniverse?.id;

  const gridRef = useRef<AgGridReact>(null);

  const [
    storePerformanceStoreSummaryLazyQuery,
    {
      loading: loadingStorePerformanceSummary,
      data: dataStorePerformanceSummary,
      refetch: refetchStorePerformanceSummary,
      called: calledStorePerformanceSummary,
    },
  ] = useStorePerformanceStoreSummaryLazyQuery({
    onError: (err) => addError(err, 'error'),
  });

  const {
    data: dataCollections,
    loading: loadingCollections,
    refetch: refetchDataCollections,
  } = useGetCollectionsNamesQuery({
    variables: { universeId: selectedUniverse?.id, isActive: true },
    onError: (err) => addError(err, 'error'),
  });

  const { data: dataStoreCustomFields } = useStoreCustomFieldsQuery({ onError: (err) => addError(err, 'error') });
  const { data: dataCapacityType } = useGetCapacityTypeQuery({ onError: (err) => addError(err, 'error') });
  const [getCollectionStoreGroups] = useGetCollectionStoreGroupsLazyQuery();

  const [allColumnDefs, setAllColumnDefs] = useState<ColDef[] | null>(null);
  const [columnDefs, setColumnDefs] = useState<ColDef[] | null>(null);
  const [rowData, setRowData] = useState<any[]>();
  const [filteredData, setFilteredData] = useState<any[]>();
  const [hasFilters, setHasFilters] = useState(false);
  const [isGridRendered, setIsGridRendered] = useState(false);
  const [collectionsStoreGroups, setCollectionsStoreGroups] = useState<{
    [collectionId: string]: { id: number; name?: string | null | undefined }[];
  } | null>(null);

  const { handleColumnMoved, handleColumnResized, handleSortChanged, handleFilterChanged, handleColumnPinned } =
    useGridCols({
      isGridReady: isGridRendered,
      tableName: 'storePerformanceAnalytics',
      gridRef,
    });

  useEffect(() => {
    rowData && setFilteredData(rowData);
  }, [rowData]);

  useEffect(() => {
    if (!universeId || !seasonId) return;

    if (calledStorePerformanceSummary) {
      refetchStorePerformanceSummary({ seasonId, universeId });
    } else {
      storePerformanceStoreSummaryLazyQuery({ variables: { seasonId, universeId } });
    }
  }, [
    storePerformanceStoreSummaryLazyQuery,
    seasonId,
    universeId,
    calledStorePerformanceSummary,
    refetchStorePerformanceSummary,
  ]);

  const getCapacityUnit = useCallback(() => {
    if (dataCapacityType?.tenantSettings.storeCapacityType === CapacityType.NotSet) return '';
    if (dataCapacityType?.tenantSettings.storeCapacityType === CapacityType.Pieces) return '(pièces)';
    if (dataCapacityType?.tenantSettings.storeCapacityType === CapacityType.SquareMeters) return '(m²)';
  }, [dataCapacityType?.tenantSettings.storeCapacityType]);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      ...GRID_DEFAULT_COL_DEFS,
      sortable: false,
      tooltipValueGetter(params) {
        const value = params.valueFormatted ? params.valueFormatted : params.value;

        return isString(value) ? value : null;
      },
      cellRenderer(params: ICellRendererParams) {
        const value = params.valueFormatted ? params.valueFormatted : params.value;

        return params.colDef?.editable ? <div className="editableCellContent"> {value}</div> : value;
      },
    };
  }, []);

  useEffect(() => {
    if (!dataCollections?.collections) return;

    const loadAllCollectionGroups = async (collectionIds: number[]) => {
      const collectionStoreGroups: { [collectionId: string]: { id: number; name?: string | null | undefined }[] } = {};

      const result = await getCollectionStoreGroups({
        variables: { collectionId: collectionIds[0] },
      });

      collectionStoreGroups[collectionIds[0]] = result.data?.storeGroups || [];

      for (let i = 1; i < collectionIds.length; i++) {
        const restResults = await getCollectionStoreGroups({ variables: { collectionId: collectionIds[i] } });
        collectionStoreGroups[collectionIds[i]] = restResults.data?.storeGroups || [];
      }

      setCollectionsStoreGroups(collectionStoreGroups);
    };

    const collectionIds = dataCollections?.collections?.map((collection) => collection.id);
    if (isEmpty(collectionIds)) {
      setCollectionsStoreGroups({});
    } else {
      loadAllCollectionGroups(collectionIds);
    }
  }, [dataCollections?.collections, getCollectionStoreGroups]);

  useEffect(() => {
    if (
      loadingStorePerformanceSummary ||
      loadingCollections ||
      !dataCollections ||
      allColumnDefs ||
      !dataStorePerformanceSummary
    )
      return;

    let colDefs: ColDef[] = [
      {
        field: 'code',
        sort: 'asc',
        sortable: true,
        headerName: t('storePerformanceAnalytics.store_code'),
        type: 'TEXT_COLUMN',
        cellClass: 'stringType',
      },
      {
        field: 'name',
        comparator: sortEmpty,
        headerName: t('common.name'),
        type: 'TEXT_COLUMN',
      },
      ...generateCustomFieldsColDefs(dataStoreCustomFields?.storeCustomFields),
    ];

    if (!isEmpty(dataCollections)) {
      const collectionsColDefs: ColDef[] = dataCollections?.collections!.map((column: any, index: number) => {
        const name = column.name || t('store.collection', { index }) || '';

        return {
          [COLLECTION_ID_COL_DEF]: column.id,
          field: `${COLLECTION_FIELD_PREFIX}${column.id}`,
          headerName: name,
          type: 'TEXT_COLUMN',
          ...CENTERED_COL_DEF,
          filter: true,
          filterParams: {
            valueFormatter: (params: any) => {
              const collectionId = params.colDef.__collectionId;
              const value =
                collectionsStoreGroups &&
                collectionsStoreGroups[collectionId].find((collection) => collection.id == params.value)?.name;

              return value || '';
            },
          },
          cellRenderer(params: ICellRendererParams) {
            const currentColDef = params.colDef as CollectionColDef;
            const collectionId = currentColDef?.[COLLECTION_ID_COL_DEF];

            if (params.data) {
              const { storeToStoreGroups } = params.data as StorePerformanceDto;
              const currentRowCollectionGroups = storeToStoreGroups?.filter(
                (collectionStoreGroup: any) => collectionStoreGroup?.collectionId === collectionId,
              );

              return !isEmpty(currentRowCollectionGroups)
                ? currentRowCollectionGroups?.map((group: any) => group?.storeGroupName).join(', ')
                : ' ';
            }

            return '';
          },
        };
      });

      colDefs.push(...collectionsColDefs);
    }

    colDefs.push(
      {
        field: 'numberOfProductsWithStock',
        valueFormatter: numberWithSpaceFormatter,
        headerName: t(`storePerformance.number_of_products_in_store`),
      },
      {
        field: 'numberOfProductsWithOptimalStock',
        valueFormatter: numberWithSpaceFormatter,
        headerName: t(`storePerformance.number_of_products_with_ideal_stock_in_store`),
      },
      {
        field: 'numberOfProductsWithSalesLastWeek',
        headerName: t(`storePerformance.number_of_articles_last_week`, { week: getCurrentWeek(7) }),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'capacity',
        valueFormatter: numberWithSpaceFormatter,
        headerName: t('storePerformanceAnalytics.capacity', { unit: getCapacityUnit() }),
      },
      {
        field: 'capacityRatio',
        headerName: t('storePerformanceAnalytics.capacity_ratio'),

        valueFormatter(params: ValueFormatterParams<StorePerformanceStoreSummaryDto>) {
          const percentage = params.data?.capacityRatio * 100;

          if (params.node === params.api.getPinnedBottomRow(0)) {
            return params.value.toFixed(0) + ' %' || '';
          } else {
            return percentage.toFixed(0) + ' %' || '';
          }
        },
      },
      {
        field: 'salesQuantityLastWeek',
        valueFormatter: numberWithSpaceFormatter,
        headerName: t(`storePerformance.previous_week_sales`, { week: getCurrentWeek(7) }),
        ...HIGHLIGHTED_COLUMN_COL_DEFS,
      },
      {
        field: 'stockQuantityLastWeek',
        valueFormatter: numberWithSpaceFormatter,
        headerName: t(`storePerformance.previous_week_stocks`, { week: getCurrentWeek(7) }),
        ...HIGHLIGHTED_COLUMN_COL_DEFS,
      },
      {
        field: 'coverageOneWeekBefore',
        valueFormatter: numberWithSpaceFormatter,
        headerName: t(`storePerformance.coverage_one_week_before`, { week: getCurrentWeek(7) }),
        ...HIGHLIGHTED_COLUMN_COL_DEFS,
      },
      {
        field: 'coverageTwoWeeksBefore',
        valueFormatter: numberWithSpaceFormatter,
        headerName: t(`storePerformance.coverage_two_weeks_before`, { week: getCurrentWeek(14) }),
        ...HIGHLIGHTED_COLUMN_COL_DEFS,
      },
      {
        field: 'coverageThreeWeeksBefore',
        valueFormatter: numberWithSpaceFormatter,
        headerName: t(`storePerformance.coverage_three_weeks_before`, { week: getCurrentWeek(21) }),
        ...HIGHLIGHTED_COLUMN_COL_DEFS,
      },
      {
        field: 'averageCoverage',
        valueFormatter: numberWithSpaceFormatter,
        headerName: t(`storePerformance.average_coverage`),
        ...HIGHLIGHTED_COLUMN_COL_DEFS,
      },
      {
        field: 'optimalStock',
        headerName: t(`storePerformance.optimal_stock`),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'optimalStockOneWeekBefore',
        valueFormatter: numberWithSpaceFormatter,
        headerName: t(`storePerformance.optimal_stock_week_before`, { week: getCurrentWeek(7) }),
      },
      {
        field: 'estimatedPicking',
        valueFormatter: numberWithSpaceFormatter,
        headerName: t(`storePerformance.estimated_picking`),
      },
    );

    colDefs = colDefs.map((colDef) => ({ ...colDef, headerTooltip: colDef.headerName }));

    setColumnDefs(getVisibleColDefs({ allColDefs: colDefs, getView }));

    setAllColumnDefs(colDefs);
  }, [
    allColumnDefs,
    dataStorePerformanceSummary,
    dataStoreCustomFields,
    loadingCollections,
    t,
    loadingStorePerformanceSummary,
    dataCollections,
    getCurrentViewCols,
    getView,
    collectionsStoreGroups,
    getCapacityUnit,
  ]);

  useEffect(() => {
    if (!allColumnDefs) return;

    setColumnDefs(getVisibleColDefs({ allColDefs: allColumnDefs, getView }));
    applyColWidths(gridRef, getColumnsWidths);
  }, [allColumnDefs, getColumnsWidths, getView]);

  useEffect(() => {
    dataCollections && refetchDataCollections();
  }, [refetchDataCollections, dataCollections]);

  useEffect(() => {
    if (!allColumnDefs) return;

    if (dataStorePerformanceSummary?.storePerformanceStoreSummary) {
      const mutableData: any = dataStorePerformanceSummary && dataStorePerformanceSummary?.storePerformanceStoreSummary;
      const rows = prepareRowData([...mutableData], allColumnDefs) as StorePerformanceStoreSummaryDto[];
      rows && setRowData(rows);
    } else {
      setRowData([]);
    }
  }, [allColumnDefs, dataStorePerformanceSummary]);

  const createData = useCallback(
    (count: number) => {
      const result: any = [];
      const calculatedData = filteredData && calculateData(filteredData);

      for (let i = 0; i < count; i++) {
        result.push({
          code: 'Total',
          valueFormatter: numberWithSpaceFormatter,
          capacityRatio:
            (filteredData && Number(calculatedData?.capacityRatioSumTotal) / Number(filteredData.length)) || 0,
          capacity: calculatedData?.capacitySumTotal,
          salesQuantityLastWeek: calculatedData?.salesQuantityLastWeekSumTotal,
          stockQuantityLastWeek: calculatedData?.stockQuantityLastWeekSumTotal,
          coverageOneWeekBefore:
            (filteredData && Number(calculatedData?.coverageOneWeekBeforeSumTotal) / Number(filteredData.length)) || 0,
          coverageTwoWeeksBefore:
            (filteredData && Number(calculatedData?.coverageTwoWeeksBeforeSumTotal) / Number(filteredData.length)) || 0,
          coverageThreeWeeksBefore:
            (filteredData && Number(calculatedData?.coverageThreeWeeksBeforeSumTotal) / Number(filteredData.length)) ||
            0,
          averageCoverage:
            (filteredData && Number(calculatedData?.averageCoverageSumTotal) / Number(filteredData.length)) || 0,
          numberOfProductsWithStock:
            (filteredData && Math.round(calculatedData?.numberOfProductsWithStockSumTotal / filteredData.length)) || 0,
          numberOfProductsWithOptimalStock:
            (filteredData &&
              Math.round(calculatedData?.numberOfProductsWithOptimalStockSumTotal / filteredData.length)) ||
            0,
          numberOfProductsWithSalesLastWeek:
            (filteredData &&
              Math.round(calculatedData?.numberOfProductsWithSalesLastWeekSumTotal / filteredData.length)) ||
            0,
          optimalStock: calculatedData?.optimalStockSumTotal,
          optimalStockOneWeekBefore: calculatedData?.optimalStockOneWeekBeforeSumTotal,
          estimatedPicking: calculatedData?.estimatedPickingSumTotal,
        });
      }

      return result;
    },
    [filteredData],
  );

  const pinnedBottomRowData = useMemo<any>(() => {
    return createData(1);
  }, [createData]);

  const excelStyles: ExcelStyle[] = [
    {
      id: 'stringType',
      dataType: 'String',
    },
  ];

  const onExport = useCallback(() => {
    gridRef.current &&
      gridRef.current.api.exportDataAsExcel({
        processCellCallback: (params) => {
          if (params.column.getColDef().field === 'capacityRatio') {
            const valueFormatterParams: ValueFormatterParams = {
              ...params,
              data: params.node?.data,
              node: params.node!,
              colDef: params.column.getColDef(),
            };

            return (params.column.getColDef().valueFormatter as ValueFormatterFunc)(valueFormatterParams);
          }

          if (isNumber(params.value) && params?.value % 1 != 0) {
            return params.value.toFixed(1);
          }

          return params.value;
        },
        fileName: `${t(
          'storePerformanceAnalytics.store_performance_totals_per_store',
        )}_${getFormattedCurrentDateTime()}`,
      });
  }, [t]);

  const onCSVExport = useCallback(() => {
    gridRef.current!.api.exportDataAsCsv();
  }, []);

  return (
    <VerticalPageContent>
      {loadingStorePerformanceSummary ? (
        <Spinner size="large" />
      ) : (
        <>
          <ActionButtonsWrapper style={{ justifyContent: 'flex-start' }}>
            <Views flatColDefs={allColumnDefs} />
            <ClearFiltersButton gridRefs={gridRef} hasFilters={hasFilters} />
            <ExportButtonWrapper style={{ marginLeft: 'auto' }}>
              <ImportExportXlsxComponent exportClickHandler={onExport} noImportOption />
            </ExportButtonWrapper>
          </ActionButtonsWrapper>
          <ConciseGridContainer isGridReady={isGridRendered}>
            <AdaptiveGridResizer>
              <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={rowData}
                defaultColDef={defaultColDef}
                domLayout={'autoHeight'}
                pinnedBottomRowData={pinnedBottomRowData}
                onFirstDataRendered={() => setIsGridRendered(true)}
                onSortChanged={(e) => handleSortChanged(null, e)}
                onColumnMoved={handleColumnMoved}
                onColumnPinned={handleColumnPinned}
                onColumnResized={handleColumnResized}
                columnTypes={COLUMN_TYPES}
                onFilterChanged={(e) => {
                  setFilteredData(e.api?.getRenderedNodes().map((node) => node.data));
                  setHasFilters(hasFiltersChanged(e));
                  handleFilterChanged();
                }}
                enableRangeSelection={true}
                getContextMenuItems={() => getContextMenu(onExport, onCSVExport)}
                sideBar={SIDE_BAR}
                excelStyles={excelStyles}
              />
            </AdaptiveGridResizer>
          </ConciseGridContainer>
        </>
      )}
    </VerticalPageContent>
  );
};

export default StorePerformanceAnalyticsTable;
