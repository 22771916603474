import React, { useCallback, useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import {
  ColDef,
  ICellRendererParams,
  NewValueParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { Popover } from 'antd';
import placeholderImage from 'assets/images/placeholder-image.png';
import { TableViewsContext, PermissionsContext } from 'context';
import { useTranslation } from 'react-i18next';
import { getCurrentWeekMinusOneDay } from 'utils/moment';

import { CellColoringHint, NumericCellEditor } from 'components/AgGridComponents';
import {
  currencyFormatter,
  formatNumberWithSpace,
  numberWithSpaceFormatter,
  percentFormatter,
} from 'components/AgGridComponents/valueFormatters';
import { generateCustomFieldsColDefs } from 'helpers/customFields';
import { getVisibleColDefs } from 'helpers/gridCols';
import { useCommonData } from 'hooks/useCommonData';
import { CONDITIONAL_FORMATTING_COLORS, HIGH_DISCOUNT_SEASON_LIMIT } from 'screens/ProductPerformance/constants';
import {
  Collection,
  ProductFleetPerformanceDto,
  ProductModule,
  useGetCollectionsLazyQuery,
  useProductCustomFieldsQuery,
  PermissionsEnum,
} from 'services/graphql/main';
import { useError } from 'services/utils';
import { SelectedViewId } from 'types/views';

interface Props {
  dataProductPerformance: ProductFleetPerformanceDto[] | null;
  errorProductPerformance: ApolloError | undefined;
  selectedUniverseId: number;
  updateProductPerformanceData: (productId: number, colId: string, newValue: number | boolean) => Promise<void>;
  updatedRowIdRef: React.MutableRefObject<number>;
}

const { RED, LIGHT_RED, LIGHT_GREEN, YELLOW, ORANGE, PINK, SHOWCASE_PRODUCT } = CONDITIONAL_FORMATTING_COLORS;

export const useColDefs = ({
  dataProductPerformance,
  errorProductPerformance,
  updatedRowIdRef,
  selectedUniverseId,
  updateProductPerformanceData,
}: Props) => {
  const { t } = useTranslation();
  const { addError } = useError();

  const { selectedViewId, pendingTableRefresh, setPendingTableRefresh } = useContext(TableViewsContext);
  const { seasonName, universeName } = useCommonData();
  const { permissions } = useContext(PermissionsContext);

  const { data: dataProductCustomFields, error: errorProductCustomFields } = useProductCustomFieldsQuery({
    onError: (err) => addError(err, 'error'),
  });
  const [getCollectionsLazyQuery] = useGetCollectionsLazyQuery({ onError: (err) => addError(err, 'error') });

  const [viewColsInitialized, setViewColsInitialized] = useState<SelectedViewId | undefined>();
  const [collections, setCollections] = useState<Collection[] | null | undefined>([]);
  const [allVisibleColumnDefs, setAllVisibleColumnDefs] = useState<ColDef[]>([]);
  const [allColDefs, setAllColDefs] = useState<ColDef[]>([]);
  const [changedCollections, setChangedCollections] = useState<{ [productId: number]: number }>({});
  const { getView } = useContext(TableViewsContext);
  useEffect(() => {
    if (!selectedUniverseId) return;
    const loadCollectionsData = async () => {
      const result = await getCollectionsLazyQuery({ variables: { universeId: selectedUniverseId } });

      if (result.data?.collections) setCollections(result.data.collections as Array<Collection>);
    };

    loadCollectionsData();
  }, [getCollectionsLazyQuery, selectedUniverseId]);

  const handleCollectionChange = useCallback((selectedCollectionId: number, productId: number) => {
    setChangedCollections((prevState) => ({ ...prevState, [productId]: selectedCollectionId }));
  }, []);

  const handleModuleChange = useCallback(
    (value: number, productId: number) => {
      updateProductPerformanceData(productId, 'moduleId', value);
      setChangedCollections((prevState) => {
        delete prevState[productId];

        return prevState;
      });
    },
    [updateProductPerformanceData],
  );

  useEffect(() => {
    if (
      (!Object.keys(changedCollections).length &&
        selectedViewId !== undefined &&
        viewColsInitialized === selectedViewId &&
        !pendingTableRefresh) ||
      errorProductPerformance ||
      errorProductCustomFields ||
      !dataProductPerformance ||
      !dataProductCustomFields?.productCustomFields ||
      updatedRowIdRef.current
    )
      return;
    const middleColDefCellRenderer = (params: ICellRendererParams) => {
      const value = params.valueFormatted ? params.valueFormatted : params.value;
      let cell = value;
      if (params.data.isOnShowcase) {
        cell = (
          <CellColoringHint
            titleKey="productPerformanceTable.popover_showcase_product_title"
            hints={[
              {
                color: SHOWCASE_PRODUCT,
                description: t('productPerformanceTable.popover_showcase_product_text'),
              },
            ]}
          >
            {value}
          </CellColoringHint>
        );
      }

      return cell;
    };

    let leftColDefs: ColDef[] = [
      {
        field: 'productCode',
        headerName: t('common.code'),
        type: 'TEXT_COLUMN',
        cellRenderer: middleColDefCellRenderer,
      },
      {
        field: 'productName',
        headerName: t('common.name'),
        type: 'TEXT_COLUMN',
        cellStyle: { justifyContent: 'flex-start' },
        cellRenderer: middleColDefCellRenderer,
      },
      {
        field: 'photoUrls',
        headerName: t('productPerformanceTable.photo'),
        filter: false,
        cellRenderer(params: ICellRendererParams) {
          const imgUrl = params.value && JSON.parse(params.value) ? JSON.parse(params.value)[0] : null;

          return imgUrl ? (
            <Popover
              content={
                <img
                  src={imgUrl}
                  alt="Product photo"
                  style={{ maxHeight: 300 }}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = placeholderImage;
                  }}
                />
              }
            >
              <div style={{ height: '100%', width: '100%' }}>
                <img
                  src={imgUrl}
                  alt="Product photo"
                  style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = placeholderImage;
                  }}
                />
              </div>
            </Popover>
          ) : (
            <img src={placeholderImage} alt="Placeholder photo" style={{ maxHeight: '100%', maxWidth: '100%' }} />
          );
        },
      },
      {
        field: 'colorCode',
        headerName: t('productPerformanceTable.color_code_abbr'),
        headerTooltip: t('productPerformanceTable.color_code'),
        type: 'TEXT_COLUMN',
        cellRenderer: middleColDefCellRenderer,
        cellClass: 'stringType',
      },
      {
        field: 'colorName',
        headerName: t('productPerformanceTable.color'),
        type: 'TEXT_COLUMN',
        cellRenderer: middleColDefCellRenderer,
      },
      {
        field: 'familyName',
        headerName: t('productPerformanceTable.family'),
        type: 'TEXT_COLUMN',
        cellRenderer: middleColDefCellRenderer,
      },
      ...generateCustomFieldsColDefs(
        dataProductCustomFields.productCustomFields,
        'customFieldValue',
        middleColDefCellRenderer,
      ),
      {
        field: 'collectionName',
        headerName: t('productPerformanceTable.collection'),
        type: 'TEXT_COLUMN',
        // minWidth: 90,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        editable: (permissions as PermissionsEnum[])?.includes(PermissionsEnum.ProductPerformanceMainTableEdit),
        cellEditorParams: {
          values: collections?.map(({ name }) => name),
        },
        onCellValueChanged: (data: NewValueParams<ProductFleetPerformanceDto>) =>
          handleCollectionChange(
            collections?.find((collection) => collection.name === data.newValue)?.id as number,
            data.data.id,
          ),
      },
      {
        field: 'productModuleName',
        headerName: t('productPerformanceTable.module_name'),
        type: 'TEXT_COLUMN',
        // minWidth: 90,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        editable(params) {
          return (
            !!params.data?.productModuleName?.length &&
            (permissions as PermissionsEnum[])?.includes(PermissionsEnum.ProductPerformanceMainTableEdit)
          );
        },
        cellEditorParams(params: ICellRendererParams) {
          if (!params.data) return;
          const { id: productId } = params.data;

          let collection;
          if (productId in changedCollections) {
            collection = collections?.find((collection) => collection.id === changedCollections[productId]);
          } else {
            collection = collections?.find((collection) => collection.name === params.data.collectionName);
          }

          const productModules = collection?.productModules as ProductModule[];

          return { values: productModules?.map(({ name }) => name) || [] };
        },
        onCellValueChanged: (data: NewValueParams<ProductFleetPerformanceDto>) => {
          const { id: productId } = data.data;
          if (!productId) return;
          let collection;
          if (productId in changedCollections) {
            collection = collections?.find((collection) => collection.id === changedCollections[productId]);
          } else {
            collection = collections?.find((collection) => collection.name === data.data.collectionName);
          }

          const productModules = collection?.productModules as ProductModule[];
          handleModuleChange(
            productModules?.find((productModule) => productModule.name === data.newValue)?.id as number,
            productId,
          );
        },
      },
      {
        field: 'sizeSystemName',
        headerName: t('productPerformanceTable.size_system'),
        type: 'TEXT_COLUMN',
        pinned: 'right',
      },
      {
        field: 'sizeSystemRowName',
        headerName: t('productPerformanceTable.size_system_row_name'),
        type: 'TEXT_COLUMN',
      },
      {
        field: 'salesPrice',
        headerName: t('productPerformanceTable.sales_price'),
        valueFormatter: currencyFormatter,
      },
    ];

    const middleColDefs: ColDef[] = [
      {
        field: 'stockWarehouse',
        headerName: t('productPerformanceTable.warehouse_stock'),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'warehouseStockRetails',
        headerName: t('productPerformanceTable.retail_stock_availability_abbr'),
        headerTooltip: t('productPerformanceTable.retail_stock_availability'),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'warehouseStockWholesale',
        headerName: t('productPerformanceTable.wholesale_stock_availability_abbr'),
        headerTooltip: t('productPerformanceTable.wholesale_stock_availability'),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'warehouseCoverage',
        valueFormatter: numberWithSpaceFormatter,
        headerName: t('productPerformanceTable.warehouse_coverage'),
        cellRenderer(params: ICellRendererParams) {
          const value = params.valueFormatted ? params.valueFormatted : params.value;
          let cell = <div className="boldCell">{value}</div>;

          if (value <= 3) {
            let cellClassName;

            if (value <= 1) {
              cellClassName = 'warehouseCoverageLeq1';
            } else if (value > 1 && value <= 2) {
              cellClassName = 'warehouseCoverageLeq2';
            } else if (value > 2) {
              cellClassName = 'warehouseCoverageLeq3';
            }

            cell = (
              <CellColoringHint
                titleKey="productPerformanceTable.warehouse_coverage"
                hints={[
                  {
                    color: PINK,
                    description: `${t('productPerformanceTable.warehouse_coverage')} <= 1`,
                  },
                  {
                    color: ORANGE,
                    description: `${t('productPerformanceTable.warehouse_coverage')} <= 2`,
                  },
                  {
                    color: YELLOW,
                    description: `${t('productPerformanceTable.warehouse_coverage')} <= 3`,
                  },
                ]}
              >
                <div className={`${cellClassName} bgColoredCell boldCell`}>{value}</div>
              </CellColoringHint>
            );
          }

          return cell;
        },
      },
      {
        field: 'quantityForDelivery',
        headerName: t('productPerformanceTable.quantity_for_delivery'),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'stockQuantityInWebStore',
        headerName: t('productPerformanceTable.stock_in_web'),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'stockQuantityInPhysicalStore',
        headerName: t('productPerformanceTable.stock_in_store'),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'quantityInTransit',
        headerName: t('productPerformanceTable.stock_in_transit'),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'quantityPicking',
        headerName: t('productPerformanceTable.quantity_picking'),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'stockTotal',
        headerName: t('productPerformanceTable.stock_total'),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'stockQuantityTotal',
        headerName: t('productPerformanceTable.stock_quantity_total'),
        headerTooltip: t('productPerformanceTable.stock_quantity_total_tooltip'),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'stockFlowRate',
        headerName: t('productPerformanceTable.stock_flow_rate'),
        headerTooltip: t('productPerformanceTable.stock_flow_rate_tooltip'),
        valueFormatter: percentFormatter,
      },
      {
        field: 'proposedStrength',
        headerName: t('productPerformanceTable.proposed_strength'),
        valueFormatter(params: ValueFormatterParams<ProductFleetPerformanceDto>) {
          return params.data?.proposedStrength.toFixed(1) || '';
        },
        cellRenderer(params: ICellRendererParams) {
          const value = params.valueFormatted ? params.valueFormatted : params.value;
          let cell = <div className="boldCell">{value}</div>;
          const getHint = (className: string) => (
            <CellColoringHint
              titleKey="productPerformanceTable.proposed_strength"
              hints={[
                {
                  color: LIGHT_RED,
                  description: `${t('productPerformanceTable.popover_pp_proposed')} < ${t(
                    'productPerformanceTable.popover_pp_validated',
                  )}`,
                },
                {
                  color: LIGHT_GREEN,
                  description: `${t('productPerformanceTable.popover_pp_proposed')} > ${t(
                    'productPerformanceTable.popover_pp_validated',
                  )}`,
                },
              ]}
            >
              <div className={className}>{value}</div>
            </CellColoringHint>
          );

          if (value < params.data.validatedStrength) {
            cell = getHint('ppValidatedGtProposed boldCell bgColoredCell');
          } else if (value > params.data.validatedStrength) {
            cell = getHint('ppProposedGtValidated boldCell bgColoredCell');
          }

          return cell;
        },
      },
      {
        field: 'validatedStrength',
        editable: true,
        valueGetter(params: ValueGetterParams<ProductFleetPerformanceDto>) {
          return params.data?.validatedStrength || '';
        },
        headerName: t('productPerformanceTable.validated_strength'),
        cellClass: 'boldCell',
        valueParser: 'Number(newValue)',
        cellEditor: NumericCellEditor,
        valueFormatter(params: ValueFormatterParams<ProductFleetPerformanceDto>) {
          return params.data?.validatedStrength.toFixed(1) || '';
        },
      },
      {
        field: 'isOnShowcase',
        headerName: t('productPerformanceTable.showcase'),
        filter: true,
      },
      {
        field: 'isSynced',
        headerName: t('productPerformanceTable.sending_is'),
        filter: true,
      },
      {
        field: 'hasStockMinOverride',
        headerName: t('productPerformanceTable.has_stock_min_override'),
        filter: true,
      },
      {
        field: 'hasStockMaxOverride',
        headerName: t('productPerformanceTable.has_stock_max_override'),
        filter: true,
      },
      {
        field: 'hasCondition',
        headerName: t('productPerformanceTable.has_condition'),
        filter: true,
      },
      {
        field: 'hasException',
        headerName: t('productPerformanceTable.has_exception'),
        filter: true,
      },
      {
        field: 'excludedStoreExceptionsCount',
        headerName: t('productPerformanceTable.excluded_store_count'),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'includedStoreExceptionsCount',
        headerName: t('productPerformanceTable.included_store_count'),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'optimalStock',
        valueFormatter: numberWithSpaceFormatter,
        headerName: t('product.optimal_stock'),
      },
      {
        field: 'estimationPicking',
        headerName: t('productPerformanceTable.estimation_picking'),
        cellRenderer(params: ICellRendererParams) {
          const value = params.valueFormatted ? params.valueFormatted : params.value;
          let cell = value;
          if (value > params.data.stockQuantityTotal) {
            const cellClassName = 'estPickingGtTotalStock bgColoredCell';

            cell = (
              <CellColoringHint
                titleKey="productPerformanceTable.estimation_picking"
                hints={[
                  {
                    color: LIGHT_RED,
                    description: `${t('productPerformanceTable.estimation_picking')} > ${t(
                      'productPerformanceTable.stock_quantity_total',
                    )}`,
                  },
                ]}
              >
                <div className={`${cellClassName} boldCell`}>{value}</div>
              </CellColoringHint>
            );
          }

          return cell;
        },
      },
      {
        field: 'numberOfStoresInWhichProductIsPresent',
        headerName: t('productPerformanceTable.number_of_stores_where_product_is_present_abbr'),
        headerTooltip: t('productPerformanceTable.number_of_stores_where_product_is_present'),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'numberOfWeeksTheProductIsSold',
        headerName: t('productPerformanceTable.number_of_weeks_product_is_sold_abbr'),
        headerTooltip: t('productPerformanceTable.number_of_weeks_product_is_sold'),
        valueFormatter: numberWithSpaceFormatter,
      },
    ];

    let rightColDefs: ColDef[] = [
      {
        field: 'webStoreSales',
        headerName: t('productPerformanceTable.web_sales'),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'physicalStoreSales',
        headerName: t('productPerformanceTable.store_sales'),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'totalQuantitySold',
        headerName: t('productPerformanceTable.total_quantity_sold'),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'webPercent',
        headerName: t('productPerformanceTable.web_percent'),
        valueFormatter: percentFormatter,
      },
      {
        field: 'weekSales',
        sort: 'desc',
        headerName: t('productPerformanceTable.week_sales', { week: getCurrentWeekMinusOneDay() }),
        valueFormatter: numberWithSpaceFormatter,
        cellRenderer(params: ICellRendererParams<ProductFleetPerformanceDto>) {
          const value = params.valueFormatted ? params.valueFormatted : params.value;
          let cell = <div className="normalDiscountSales boldCell">{value}</div>;

          if (params.data && params.data.weekDiscountStockRate > HIGH_DISCOUNT_SEASON_LIMIT) {
            cell = (
              <CellColoringHint
                titleKey="productPerformanceTable.discount_rate_per_season"
                hints={[
                  {
                    color: RED,
                    description: t('productPerformanceTable.popover_hint_discount_higher_than_percent', {
                      percent: HIGH_DISCOUNT_SEASON_LIMIT,
                    }),
                  },
                ]}
              >
                <div className="highDiscountSales boldCell">{value}</div>
              </CellColoringHint>
            );
          }

          return cell;
        },
      },
      {
        field: 'pastWeekSales',
        headerName: t('productPerformanceTable.sales_last_week', { week: getCurrentWeekMinusOneDay(7) }),
        valueFormatter: numberWithSpaceFormatter,
        comparator: (valueA, valueB, nodeA, nodeB) => {
          if (valueA === valueB) {
            if (nodeA.data.productCode === nodeB.data.productCode) {
              return nodeA.data.id < nodeB.data.id ? 1 : -1;
            }

            return nodeA.data.productCode < nodeB.data.productCode ? 1 : -1;
          }

          return valueA > valueB ? 1 : -1;
        },
      },
      {
        field: 'pastTwoWeekSales',
        headerName: t('productPerformanceTable.sales_last_2_weeks', { week: getCurrentWeekMinusOneDay(14) }),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'pastThreeWeekSales',
        headerName: t('productPerformanceTable.sales_last_3_weeks', { week: getCurrentWeekMinusOneDay(21) }),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'weekDiscountStockRate',
        headerName: t('productPerformanceTable.discount_rate_per_season', { week: getCurrentWeekMinusOneDay() }),
        valueFormatter: percentFormatter,
      },
      {
        field: 'potentialSalesPastWeekAllStores',
        headerName: t('productPerformanceTable.potential_sales_last_week', { week: getCurrentWeekMinusOneDay(7) }),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'averagePastThreeWeeksSalesAllStores',
        headerName: t('productPerformanceTable.average_three_week_sales_all_stores'),
        valueFormatter: numberWithSpaceFormatter,
      },
      {
        field: 'potentialWeeklySalesFlow',
        headerName: t('productPerformanceTable.weekly_sales_flow', { week: getCurrentWeekMinusOneDay(7) }),
        cellStyle: { color: 'blue' },
        valueFormatter(params: ValueFormatterParams<ProductFleetPerformanceDto>) {
          return params.data?.potentialWeeklySalesFlow.toFixed(1) || '';
        },
      },
      {
        field: 'season',
        headerName: t('common.season'),
        valueFormatter: () => (seasonName ? seasonName : ''),
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
      {
        field: 'universe',
        headerName: t('common.universe'),
        valueFormatter: () => (universeName ? universeName : ''),
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
    ];

    rightColDefs = rightColDefs.map((colDef) => ({ ...colDef, pinned: 'right' }));
    leftColDefs = leftColDefs.map((colDef) => ({ ...colDef, pinned: 'left' }));

    const allColDefs = [...leftColDefs, ...middleColDefs, ...rightColDefs];
    allColDefs.forEach((colDef) => {
      if (!colDef.headerTooltip) {
        colDef.headerTooltip = colDef.headerName;
      }
    });
    setAllColDefs(allColDefs);
    setAllVisibleColumnDefs(getVisibleColDefs({ allColDefs, getView }));
    setViewColsInitialized(selectedViewId);
    setPendingTableRefresh(false);
  }, [
    changedCollections,
    collections,
    dataProductCustomFields?.productCustomFields,
    dataProductPerformance,
    errorProductCustomFields,
    errorProductPerformance,
    handleCollectionChange,
    handleModuleChange,
    pendingTableRefresh,
    selectedViewId,
    setPendingTableRefresh,
    t,
    updatedRowIdRef,
    viewColsInitialized,
    seasonName,
    universeName,
    getView,
    permissions,
  ]);

  return {
    allVisibleColumnDefs,
    allColDefs,
  };
};
