import moment from 'moment';

export const getFormattedCurrentDateTime = () => {
  return moment(new Date()).local().format('YYYY-MM-DD HH:mm:ss');
};

export const getCurrentWeekMinusOneDay = (dayBefore = 0) => {
  return moment(new Date())
    .add(-(1 + dayBefore), 'days')
    .isoWeek();
};

export const getCurrentWeek = (dayBefore = 0) => {
  return moment(new Date()).add(-dayBefore, 'days').isoWeek();
};
